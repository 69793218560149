@media (max-width: $screen-lg) {
    // lg-md view
    .footer-holder { 
        .footer-main { 
            .footer-main-holder {
                padding: 66px 0 0; 
                .contacts { 
                    width: 38%;
                    .contacts-holder { 
                        .legal { 
                            margin: 189px 0 0;
                        }
                    }
                }

                .contact-form { 
                    width: 47.4%;
                    .contact-form-holder { 
                        form {
                            .form-field { 
                                label.input {
                                    textarea {
                                        height: 137px;
                                    }
                                }
                            }

                            .action { 
                                .submit {
                                    .btn {
                                        height: 63px;
                                    }
                                }

                                .agree-col {
                                    margin: 16px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .footer-holder { 
        .footer-main { 
            .footer-main-holder {
                padding: 33px 0 0;
                .contacts { 
                    margin: 7px 0 0;
                    width: 49%;
                    .contacts-holder { 
                        .logo {
                            margin: 0 0 37px;
                            a {
                                svg {
                                    width: 120px;
                                    height: auto;
                                }
                            }
                        }

                        .footer-menu { 
                            margin: 0 0 18px;
                            nav { 
                                ul {
                                    li {
                                        font-size: 12px;
                                        margin: 0 0 7px;
                                    }
                                }
                            }
                        }

                        .contacts-infoes { 
                            .info { 
                                font-size: 12px;
                                margin: 0 0 8px;
                            }
                        }

                        .legal { 
                            margin: 153px 0 0;
                            p {
                                font-size: 12px;
                                margin: 0 0 9px;
                            }
                        }
                    }
                }

                .contact-form { 
                    width: 43.1%;
                    .contact-form-holder { 
                        form {
                            .heading { 
                                margin: 0 0 7px;
                                >* {
                                    font-size: 28px;
                                    font-style: normal;
                                    font-weight: 400;
                                }
                            }

                            .form-field { 
                                margin-bottom: 24px;
                                label.input { 
                                    textarea {
                                        height: 41px;
                                    }
                                }

                                label.checkcontainer {
                                    margin: 6px 0 0;
                                }
                            }

                            .action { 
                                margin: 4px 0 0;
                                .submit {
                                    .btn {
                                        font-size: 16px;
                                        width: 310px;
                                        height: 55px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
