@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    form {
        .action {
            .agree-col {
                font-size: 12px;
            }
        }


        .form-field {
            label.select, label.input, label.checkcontainer, label.file-upload {
                .name {
                    font-size: 12px;
                }
            }

            label.checkcontainer {
                .checkmark {
                    width: 18px;
                    height: 18px;
                    &::after {
                        width: 4px;
                        height: 10px;
                    }
                }
                .name {
                    width: calc(100% - 18px - 10px);
                    line-height: 18px;
                }
            }

            label.input {
                input, textarea {
                    font-size: 12px;
                    @include placeholder {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
