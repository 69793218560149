@media (max-width: $screen-sm) {
    // sm-view
    html {
        font-size: $font-size-base-xs;
        line-height: $line-height-computed-xs;
    }

    .wrapper {
    	min-width: 290px;
    	max-width: 100%;
    }

    .wrapper-inside {
        width: 90.625%;
    }

    /* Header
    -----------------------------------------------------------------------------*/
    .header {

    }

    /* Middle
    -----------------------------------------------------------------------------*/
    .content {
        padding: 0 0 $footer-size-xs;
    }

    /* Footer
    -----------------------------------------------------------------------------*/
    .footer {
        margin: -$footer-size-xs auto 0;
	    height: $footer-size-xs;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
