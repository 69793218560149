@media (max-width: $screen-lg) {
    // lg-md view
    .general-popup-container { 
    
        .general-popup-container-holder { 
            .policy { 
                width: 1110px;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view

    .popup {
        .custom-close {
            right: 15px;
            top: 15px;
            a {
                svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    .general-popup-container { 
    
        .general-popup-container-holder { 

            .popup-form { 
                width: 352px;
                padding: 20px;
                .icon {
                    margin: 0 0 15px;
                    svg {
                        width: 40px;
                        height: 40px;
                    }
                }

                .heading { 
                    font-size: 18px;
                    margin: 0 0 10px;
                }

                .note {
                    font-size: 12px;
                }
            }

            .policy { 
                width: 720px;
                padding: 20px;
                h2, .h2 { 
                    font-size: 18px;
                    margin: 0 0 15px;
                }

                p {
                    font-size: 12px;
                }

                h3, .h3 { 
                    font-size: 16px;
                    margin: 0 0 30px;
                }

                h4, .h4 { 
                    font-size: 14px;
                    margin: 0 0 15px;
                }

                ul {
                    li {
                        font-size: 12px;
                    }
                }
                
            }
        }
    }
}
