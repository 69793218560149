@media (max-width: $screen-lg) {
    // lg-md view
    .header-holder { 
        .header-main { 
            .header-main-holder { 
                .left {
                    width: 32%;
                }

                .right { 
                    width: 56.3%;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .header-holder {
        .header-main { 
            .header-main-holder { 
                position: relative;
                .left {
                    display: none;
                }

                .right {
                    width: 100%;
                    .mobile-menu {
                        float: left;
                        padding: 15px 0 0;
                        &.open {
                            >a {
                                .close {
                                    opacity: 1;
                                    pointer-events: all;
                                    touch-action: auto;
                                    transition: all 500ms;
                                }

                                .open {
                                    opacity: 0;
                                    pointer-events: none;
                                    touch-action: none;
                                    transition: all 500ms;
                                }
                            }
                            .menu-mobile-dropdown {
                                height: 40px;
                                pointer-events: all;
                                touch-action: auto;
                                transition: all 600ms;
                                .menu-mobile-dropdown-holder {
                                    
                                }
                            }
                        }
                        >a {
                            display: inline-block;
                            width: 24px;
                            height: 25px;
                            position: relative;
                            .open, .close {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                                transition: all 300ms;
                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .close {
                                opacity: 0;
                                pointer-events: none;
                                touch-action: none;
                            }
                        }

                        .menu-mobile-dropdown {
                            position: absolute;
                            left: 0;
                            top: 100%;
                            width: 100%;
                            height: 0px;
                            overflow: hidden;
                            transition: all 300ms;
                            pointer-events: none;
                            touch-action: none;
                            width: 110.35%;
                            margin-left: -5.175%;
                            .menu-mobile-dropdown-holder {
                                display: inline-block;
                                width: 100%;
                                padding: 10px 4.69% 10px;
                                box-sizing: border-box;
                                background: white;
                                .menu {
                                    width: 100%;
                                    nav { 
                                        padding: 5px 0 5px;
                                        ul { 
                                            margin-left: -14px;
                                            li { 
                                                padding: 0 13.5px;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 300;
                                                line-height: normal;
                                                a { 
                                                    text-decoration: none;
                                                    position: relative;
                                                    &:hover {
                                                        &:after {
                                                            opacity: 1;
                                                            width: 125%;
                                                            transition: all 300ms;
                                                        }
                                                    }
                                                    &:after {
                                                        content: "";
                                                        display: inline-block;
                                                        width: 1%;
                                                        height: 1px;
                                                        position: absolute;
                                                        left: 50%;
                                                        bottom: -5px;
                                                        border-radius: 100px;
                                                        transform: translateX(-50%);
                                                        background: $gray-base;
                                                        opacity: 0;
                                                        transition: all 300ms;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .request-form-link {
                                    width: 110.35%;
                                    margin-left: -5.175%;
                                    float: none;
                                    .btn {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }

                    .logo { 
                        float: left;
                        margin: 0 0 0 16px;
                        a {
                            svg {
                                width: 87px;
                                height: auto;
                            }
                        }
                    }

                    .request-form-link {
                        float: right;
                        width: 320px;
                        .btn {
                            font-size: 16px;
                            height: 54px;
                            .icon {
                                width: 10px;
                                height: 10px;
                                margin-left: 11px;
                            }
                        }
                    }
                }
            }
        }
    }
}
