.header-holder { 
    display: inline-block;
    width: 100%;
	.header-main { 
        display: inline-block;
        width: 100%;
		.header-main-holder { 
            display: inline-block;
            width: 100%;
            .left { 
                float: left;
                width: 30%;
                .main-menu { 

                    nav { 
                        padding: 28px 0 0;
                        ul { 
                            margin-left: -18px;
                            li { 
                                padding: 0 18px;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                                a { 
                                    text-decoration: none;
                                    position: relative;
                                    &:hover {
                                        &:after {
                                            opacity: 1;
                                            width: 125%;
                                            transition: all 300ms;
                                        }
                                    }
                                    &:after {
                                        content: "";
                                        display: inline-block;
                                        width: 1%;
                                        height: 1px;
                                        position: absolute;
                                        left: 50%;
                                        bottom: -5px;
                                        border-radius: 100px;
                                        transform: translateX(-50%);
                                        background: $gray-base;
                                        opacity: 0;
                                        transition: all 300ms;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right { 
                float: right;
                width: 42.5%;
                .logo { 
                    float: left;
                    padding: 17px 0 0;
                    a { 

                        svg { 
                            
                        }
                    }
                }

                .request-form-link { 
                    float: right;
                    width: 415px;
                    .btn { 
                        height: 80px;
                        width: 100%;
                        leading-trim: both;
                        text-edge: cap;
                        font-family: Open Sans;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        float: right;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        animation: btn-jello-out;
                        animation-duration: 800ms;
                        animation-iteration-count: 1;
                        padding: 0;
                        transition: all 300ms;
                        .text { 

                        }

                        .icon { 
                            margin-left: 15px;
                            width: 14px;
                            height: 16px;
                            svg { 
                               width: 100%;
                               height: 100%;
                            }
                        }
                        &:hover {
                            width: 103.614%;
                            transition: all 300ms;
                        }
                    }
                }
            }
        }
	}
}

@import "../../media/tablet/includes/base/header";
@import "../../media/mobile/includes/base/header";
