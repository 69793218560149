.fancybox-container.custom-fancybox {
    .fancybox-content {
        padding: 0;
        overflow: initial;
    }
    button.fancybox-button.fancybox-close-small {
        display: none;
    }
}

.popup {
    position: relative;
    .custom-close {
        position: absolute;
        right: 39px;
        top: 28px;
        a {
            display: inline-block;
            text-decoration: none;
            svg {
                
            }
        }
    }
}

.general-popup-container { 
    
	.general-popup-container-holder { 

		.popup-form { 
            display: inline-block;
            width: 736px;
            box-sizing: border-box;
            padding: 60px;
			.icon { 
                display: inline-block;
                width: 100%;
                text-align: center;
                margin: 0 0 29px;
				svg { 
                    width: 80px;
                    height: 80px;
				}
			}

			.heading { 
                display: inline-block;
                width: 100%;
                font-size: 38px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-align: center;
                margin: 0 0 20px;
				strong { 

				}
			}

			.note { 
                display: inline-block;
                width: 100%;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                color: $gray;
                text-align: center;
			}
		}

        .policy { 
            width: 1470px;
            max-width: 95vw;
            max-height: 93vh;
            overflow-y: auto;
            box-sizing: border-box;
            padding: 50px;
            h2, .h2 { 
                font-size: 48px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin:  0 0 15px;
            }
        
            p { 
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                color: $gray;
                margin: 0;
            }
        
            h3, .h3 { 
                font-size: 28px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 0 0 29px
            }
        
            h4, .h4 { 
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin:  0 0 15px;
            }
        
            ul { 
                margin-top: 5px;
                margin-bottom: 0px;
                li { 
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }

            hr {
                border: none;
                margin: 0;
                margin-bottom: 29px;
            }
        }
	}
}

@import "../../media/tablet/includes/popups/custom_fancybox";
@import "../../media/mobile/includes/popups/custom_fancybox";
