@media (max-width: $screen-sm) {
    // sm-view
    .general-popup-container { 
    
        .general-popup-container-holder { 
            .popup-form {
                width: 290px;
                padding: 20px 35px;
            }

            .policy { 
                width: 90vw;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
