@media (max-width: $screen-sm) {
    // sm-view
    .header-holder {
        .header-main { 
            .header-main-holder { 
                .right {
                    .mobile-menu {
                        float: right;
                        padding: 20px 0 0;
                        &.open {
                            .menu-mobile-dropdown {
                                height: 130px;
                                .menu-mobile-dropdown-holder {
                                    
                                }
                            }
                        }
                        .menu-mobile-dropdown {
                            .menu-mobile-dropdown-holder {
                                .menu {
                                    nav {
                                        padding: 23px 0 5px;
                                    }
                                }
                            }
                        }
                    }

                    .logo { 
                        margin: 0;
                        padding: 20px 0 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
