.footer-holder { 
    display: inline-block;
    width: 100%;
	.footer-main { 
        display: inline-block;
        width: 100%;
		.footer-main-holder { 
            display: inline-block;
            width: 100%;
            padding: 87px 0 0;
            .contacts { 
                float: left;
                width: 36%;
                margin: 12px 0 0;
                .contacts-holder { 
                    display: inline-block;
                    width: 100%;
                    .logo { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 55px;
                        a { 

                            svg { 

                            }
                        }
                    }

                    .footer-menu { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 30px;
                        nav { 
                            display: inline-block;
                            width: 100%;
                            ul { 
                                display: inline-block;
                                width: 100%;
                                li { 
                                    display: inline-block;
                                    width: 100%;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: normal;
                                    margin: 0 0 14px;
                                    a { 
                                        color: white;
                                        text-decoration: none;
                                        position: relative;
                                        &:hover {
                                            &:after {
                                                opacity: 1;
                                                width: 100%;
                                                transition: all 300ms;
                                            }
                                        }
                                        &:after {
                                            content: "";
                                            display: inline-block;
                                            width: 1%;
                                            height: 1px;
                                            position: absolute;
                                            left: 50%;
                                            bottom: -5px;
                                            border-radius: 100px;
                                            transform: translateX(-50%);
                                            background: white;
                                            opacity: 0;
                                            transition: all 300ms;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .contacts-infoes { 
                        display: inline-block;
                        width: 100%;
                        .info { 
                            display: inline-block;
                            width: 100%;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin: 0 0 16px;
                            a { 
                                color: white;
                                text-decoration: none;
                                position: relative;
                                &:hover {
                                    &:after {
                                        opacity: 1;
                                        width: 100%;
                                        transition: all 300ms;
                                    }
                                }
                                &:after {
                                    content: "";
                                    display: inline-block;
                                    width: 1%;
                                    height: 1px;
                                    position: absolute;
                                    left: 50%;
                                    bottom: -5px;
                                    border-radius: 100px;
                                    transform: translateX(-50%);
                                    background: white;
                                    opacity: 0;
                                    transition: all 300ms;
                                }
                            }
                        }
                    }

                    .legal { 
                        display: inline-block;
                        width: 100%;
                        margin: 218px 0 0;
                        p { 
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            color: #D9D0CF;
                            margin: 0 0 15px;
                        }
                    }
                }
            }

            .contact-form { 
                float: right;
                width: 42.5%;
                .contact-form-holder { 
                    display: inline-block;
                    width: 100%;
                    form { 
                        display: inline-block;
                        width: 100%;
                        .heading { 
                            display: inline-block;
                            width: 100%;
                            margin: 0 0 16px;
                            >* { 
                                margin: 0;
                            }
                        }

                        .form-field { 
                            margin-bottom: 26px;
                            label.input { 

                                .name { 

                                }

                                input { 

                                }
                            }

                            label.checkcontainer {
                                margin: 13px 0 0;
                            }
                        }

                        .action { 
                            margin: 13px 0 0;
                            .submit { 

                                .btn { 
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 300px;
                                    height: 60px;
                                    transition: all 300ms;
                                    &:hover {
                                        width: 100%;
                                        transition: all 300ms;
                                    }
                                }
                            }

                            .agree-col { 

                            }
                        }

                        .form-group { 

                            .form-col { 

                               
                            }
                            
                        }
                    }
                }
            }
        }
	}
}

@import "../../media/tablet/includes/base/footer";
@import "../../media/mobile/includes/base/footer";
