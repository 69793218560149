@media (max-width: $screen-sm) {
    // sm-view
    .footer-holder { 
        .footer-main { 
            .footer-main-holder {
                display: flex;
                flex-direction: column-reverse;
                .contacts { 
                    width: 100%;
                    margin: 53px 0 0;
                    .contacts-holder { 
                        .legal {
                            margin: 31px 0 0;
                        }
                    }
                }

                .contact-form { 
                    width: 100%;
                    .contact-form-holder { 
                        form {
                            .action { 
                                .submit {
                                    .btn {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
